import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
//import { API } from '@stoplight/elements';
//import '@stoplight/elements/styles/elements.scss';

function App() {
  return (
    <div className="App">
    <SwaggerUI url="https://atlas-apis.locatorx.com/atlasOA3.json" /> 
{/*      <API apiDescriptionUrl="https://atlas-apis.locatorx.com/atlasOA3.json"></API> 
      <API apiDescriptionUrl="http://localhost:3000/atlasOA3.json"></API> */}
    </div>
  );
}

export default App;
